import {createContext, useCallback, useContext, useEffect, useState} from "react";
import useApi from "../hooks/useApi";

const commands = {
    contact: "kontakt?populate=*",
    courses: "courses?populate=*",
    coursesInfoText: "uue-kurse-info-text",
    impressum: "impressum",
    assets: "assets?populate=*",
    offers: "additionaloffers?populate=*&sort=rank:asc",
    projects: "projects?populate=*",
    classes: "classes?populate=*&sort=Klasse:asc",
    elternverein: "elternverein?populate=*",
    partnerschools: "partnerschules?populate=*",
    leitbild: "leitbild?populate=*",
    video: "video",
    signs: "auszeichnungens?populate=*",
    communication: "kel-gespraeche?populate=*",
    untis: "untis-info?populate=*",
    blockings: "blocking?populate=*",
    semester: "current-semester",
    exams: "exam-dates",
    buffet: "buffet?populate=*",
    ecdl: "ecdl-dates",
    team: "team-members?populate=*&sort=rank:asc",
};

const ContentContext = createContext();

export const useContent = (command) => {
    const {getContent} = useContext(ContentContext);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!command) {
            return;
        }

        const fetchData = async () => {
            try {
                setLoading(true);
                const result = await getContent(command);
                setData(result);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [command]);

    return {data, loading, error};
};

export const ContentProvider = ({children}) => {
    const {sendRequest} = useApi();
    const [contentData, setContentData] = useState({});

    const fetchContent = useCallback(async (command) => {
        if (!commands[command]) {
            throw new Error(`Unknown command: ${command}`);
        }

        const response = await sendRequest(commands[command]);
        const data = Array.isArray(response.data) ? response.data : response.data.attributes;
        setContentData((prevState) => ({
            ...prevState,
            [command]: data,
        }));
        return data;
    }, [sendRequest, commands]);

    const getContent = useCallback(async (command) => {
        if (contentData[command]) {
            return contentData[command];
        }
        return await fetchContent(command);
    }, [contentData, fetchContent]);

    return (
        <ContentContext.Provider value={{getContent}}>
            {children}
        </ContentContext.Provider>
    );
};
