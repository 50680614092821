import React, {useMemo} from 'react';
import * as FaIcons from 'react-icons/fa';
import './Team.css';
import {useContent} from "../../../context/ContentProvider";

export default function Team() {
    const {data: teamMembers} = useContent("team");

    const groupedTeamMembers = useMemo(() => {
        if (!teamMembers) {
            return null;
        }
        return teamMembers.reduce((groupedMembers, member) => {
            (groupedMembers[member.attributes.position] = groupedMembers[member.attributes.position] || []).push(member);
            return groupedMembers;
        }, {});
    }, [teamMembers]);

    return teamMembers && (
        <div className="container my-5">
            {Object.keys(groupedTeamMembers).map(position => (
                <div className="mb-5" key={position}>
                    <h2 className="mb-4">{position}</h2>
                    {groupedTeamMembers[position].map(member => (
                        <div className="row mb-4" key={member.id}>
                            <div className="col d-flex">
                                {
                                    member.attributes.image.data ?
                                        <img className={"me-4"} height={"160px"} width={"120px"}
                                             src={process.env.REACT_APP_BASE_URL + member.attributes.image.data.attributes.url}
                                             alt={member.attributes.name}/> : ""
                                }
                                <div>
                                    <div className="lead fw-bold">{member.attributes.name}</div>
                                    <div>{member.attributes.role}</div>
                                </div>
                            </div>
                            <div className="col">
                                {
                                    member.attributes.email ?
                                        <>
                                            <FaIcons.FaEnvelope className="me-2 fs-5"/>
                                            <a className="text-decoration-none"
                                               href={`mailto:${member.attributes.email}`}>{member.attributes.email}</a>
                                        </> : ""
                                }
                            </div>
                            <div className="col">
                                {
                                    member.attributes.sprechstunde ?
                                        <span title="Sprechstunde">
                                            <FaIcons.FaClock className="me-2 fs-5"/>{member.attributes.sprechstunde}
                                        </span> : ""
                                }
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}
